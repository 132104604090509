namespace HillChart.Client.Imports.Browser

open Browser.Types
open Fable.Core
open Fable.Core.JsInterop


module Image =
    let awaitLoad (image:HTMLImageElement): JS.Promise<unit> =
        Promise.create (fun resolve reject ->
            //TODO: should set some kind of timeout / failure path. Can use onerror
            image.addEventListener("load", (fun _ ->
                resolve()
            ))
        )

[<StringEnum>]
type WellKnownDirectory =
| Desktop
| Documents
| Downloads
| Music
| Pictures
| Videos


type AcceptFileType = {
    mimeType: string
    extensions: string array
}

module AcceptFileType =
    let makeAcceptObject (acceptFileTypes: AcceptFileType array) =
        acceptFileTypes 
        |> Array.map (fun accept -> accept.mimeType ==> accept.extensions)
        |> createObj

[<AllowNullLiteral>]
[<Global>]
type FilePickerTypeRestriction 
    [<ParamObject; Emit("$0")>]
    (accept: obj,?description: string) =
    /// A boolean value that defaults to false. By default, the picker should include an option to not apply any file type filters (instigated with the type option below). Setting this option to true means that option is not available.
    member val description: string = jsNative with get, set
    /// An Object with the keys set to the MIME type and the values an Array of file extensions
    /// i.e `{"text/plain": ["txt"]}`
    /// Can't figure out how to bind mime types to object keys, so I just have to dynamically type it
    member val accept: obj = jsNative with get, set
    

[<AllowNullLiteral>]
[<Global>]
type FilePickerOptions 
    [<ParamObject; Emit("$0")>]
    (?excludeAcceptAllOption: bool,
     ?id: string, 
     ?startIn: WellKnownDirectory,
     ?suggestedName: string,
     ?types: FilePickerTypeRestriction array) =
    /// A boolean value that defaults to false. By default, the picker should include an option to not apply any file type filters (instigated with the type option below). Setting this option to true means that option is not available.
    member val excludeAcceptAllOption: bool = jsNative with get, set
    /// By specifying an ID, the browser can remember different directories for different IDs. If the same ID is used for another picker, the picker opens in the same directory.
    member val id: string = jsNative with get, set
    /// A FileSystemHandle or a well known directory ("desktop", "documents", "downloads", "music", "pictures", or "videos") to open the dialog in.
    member val startIn: WellKnownDirectory = jsNative with get, set
    member val suggestedName: string = jsNative with get, set
    member val types: FilePickerTypeRestriction array = jsNative with get, set

[<Global>]
type OpenFileOptions [<ParamObject; Emit("$0")>]
    (?excludeAcceptAllOption: bool,
     ?id: string, 
     ?startIn: WellKnownDirectory,
     ?suggestedName: string,
     ?types: FilePickerTypeRestriction array,
     ?multiple: bool) = 
    /// A boolean value that defaults to false. By default, the picker should include an option to not apply any file type filters (instigated with the type option below). Setting this option to true means that option is not available.
    member val excludeAcceptAllOption: bool = jsNative with get, set
    /// By specifying an ID, the browser can remember different directories for different IDs. If the same ID is used for another picker, the picker opens in the same directory.
    member val id: string = jsNative with get, set
    /// A FileSystemHandle or a well known directory ("desktop", "documents", "downloads", "music", "pictures", or "videos") to open the dialog in.
    member val startIn: WellKnownDirectory = jsNative with get, set
    member val suggestedName: string = jsNative with get, set
    member val types: FilePickerTypeRestriction array = jsNative with get, set
    member val multiple: bool = jsNative with get, set

[<StringEnum>]
type FileSystemHandleKind = 
| File 
| Directory

type PermissionStatus = 
    interface end

/// Docs: https://developer.mozilla.org/en-US/docs/Web/API/FileSystemHandle
[<Global>]
[<AbstractClass>]
type FileSystemHandle = 
    member _.kind : FileSystemHandleKind = jsNative
    member _.name : string = jsNative
    member _.requestPermission () : JS.Promise<PermissionStatus> = jsNative
    member _.queryPermission () : JS.Promise<PermissionStatus> = jsNative
    member _.remove() : JS.Promise<unit> = jsNative
    member _.isSameEntry(fileHandle: FileSystemHandle) : JS.Promise<bool> = jsNative
    

[<Global>]
type WritableStream = 
    member _.locked :bool = jsNative 
    member _.close() : JS.Promise<unit> = jsNative
    member _.abort(?reason: string) : JS.Promise<unit> = jsNative

[<Global>]
type FileSystemWritableFileStream = 
    inherit WritableStream

    // Arguments can be quite complicate here, but for now I'll just accept string
    /// https://developer.mozilla.org/en-US/docs/Web/API/FileSystemWritableFileStream/write
    member _.write (data: string) : JS.Promise<unit> = jsNative
    member _.seek (position: int) : JS.Promise<unit> = jsNative
    member _.truncate (sizeInBytes: int) : JS.Promise<unit> = jsNative



/// https://developer.mozilla.org/en-US/docs/Web/API/FileSystemWritableFileStream
[<Global>]
[<AbstractClass>]
type FileSystemFileHandle = 
    inherit FileSystemHandle

    member _.getFile () : JS.Promise<File> = jsNative
    member _.createWritable (): JS.Promise<FileSystemWritableFileStream> = jsNative
    // member _.createSyncAccessHandle () 
    

type FileHandle = 
    //NOTE: I think I could add these to window using type Window with ... like they do with indexedDB https://github.com/fable-compiler/fable-browser/blob/e6eed23444de4c42ab5dcc5ab2ca3c225263665c/src/IndexedDB/Browser.IndexedDB.Ext.fs#L6
    static member showOpenFilePicker (?options: OpenFileOptions) : JS.Promise<FileSystemHandle array> =
        Browser.Dom.window?showOpenFilePicker(options) 

    static member showSaveFilePicker (?options: FilePickerOptions) : JS.Promise<FileSystemFileHandle> =
        Browser.Dom.window?showSaveFilePicker(options) 
namespace HillChart.Client.Domain
open Fable.Core
open System
[<StringEnum>]
type Color = 
    | Red
    | Blue
    | Green
    | Yellow

module Color =
    let toWebColor (color: Color): string =
        let translated = 
            match color with
            | Red -> "red" 
            | Blue -> "blue" 
            | Green -> "green" 
            | Yellow -> "yellow"
        translated

    let ofWebColor (webColor: string) : Color =
        let knownColors = dict ["red",Red; "blue", Blue; "green", Green; "yellow", Yellow]

        let webColor = webColor.ToLower()
        let finalColor = 
            if (knownColors.ContainsKey(webColor)) then
                knownColors[webColor]
            else Red
        finalColor
        // else if(new Regex)
        // ^#(?:[0-9a-fA-F]{3}){1,2}$

type WorkItemId = Guid
module WorkItemId = 
    let ofString (idString:string) :WorkItemId = Guid(idString) 
    let create () = Guid.NewGuid()

type WorkItemProgress = float
module WorkItemProgress =
    let ofPercentage (percent: float) =
        percent

type WorkItemDescription = string

type WorkItem = {
    id: WorkItemId
    color: Color
    description: WorkItemDescription
    progress: WorkItemProgress
}

module WorkItem =
    
    let updateWorkItem (point: WorkItem) (collection: WorkItem array) =
        collection
        |> Array.where (_.id >> ((<>) point.id))
        |> Array.append [|point|]

    let removeWorkItem (point: WorkItem) (collection: WorkItem array) =
        collection
        |> Array.where (_.id >> ((<>) point.id))


type WorkGroupCheckpoint = {
    WorkItems: WorkItem array
    DateRepresented: DateTime
}

type WorkGroup = {
    Title: string
    CurrentState: WorkItem array
    Checkpoints: WorkGroupCheckpoint array
}


module WorkGroup =
    
    let replacePointById (workItem: WorkItem) (workGroup: WorkGroup) =
        { workGroup with CurrentState = workGroup.CurrentState |> WorkItem.updateWorkItem workItem}

    let removeWorkItem (workItem: WorkItem) (workGroup: WorkGroup) =
        { workGroup with CurrentState = workGroup.CurrentState |> WorkItem.removeWorkItem workItem}

    let checkpointFromCurrent (workGroup:WorkGroup) : WorkGroupCheckpoint =
        {
            WorkItems = workGroup.CurrentState
            DateRepresented = DateTime.UtcNow
        } 
namespace HillChart.Client.Serialization

open HillChart.Client.Domain
open System

module Serialization =

    module JSON = 
        open Fable.Core.JsInterop
        let stringify data = Fable.Core.JS.JSON.stringify(data)
        let parse json : 'a = !!Fable.Core.JS.JSON.parse(json)


    type WorkItemDTO = {
        id: string
        color: string
        description: string
        progress: float
    }
    module WorkItemDTO = 
        let ofWorkItem (workItem: WorkItem) : WorkItemDTO =
            {
                id = workItem.id |> string
                color = workItem.color |> Color.toWebColor
                description = workItem.description
                progress = workItem.progress
            }
        let toWorkItem (dto: WorkItemDTO) : WorkItem =
            {
                id = dto.id |> Guid
                color = dto.color |> Color.ofWebColor
                description = dto.description
                progress = dto.progress
            }

    type CheckpointDTO = {
        DateRepresented: string
        WorkItems: WorkItemDTO array
    }
    module CheckpointDTO = 
        let ofCheckPoint (checkpoint: WorkGroupCheckpoint) : CheckpointDTO =
            {
                DateRepresented = checkpoint.DateRepresented |> _.ToString("O")
                WorkItems = checkpoint.WorkItems |> Array.map WorkItemDTO.ofWorkItem
            } 

        let toCheckpoint (dto: CheckpointDTO) : WorkGroupCheckpoint =
            {
                DateRepresented = dto.DateRepresented |> DateTime.Parse
                WorkItems = dto.WorkItems |> Array.map WorkItemDTO.toWorkItem
            }

    type WorkGroupDTO = {
        Title: string
        CurrentState: WorkItemDTO array
        Checkpoints: CheckpointDTO array
    }

    module WorkGroupDTO =
        let ofWorkGroup (workGroup: WorkGroup) : WorkGroupDTO =
            {
                Title = workGroup.Title
                CurrentState = workGroup.CurrentState |> Array.map WorkItemDTO.ofWorkItem
                Checkpoints = workGroup.Checkpoints |> Array.map CheckpointDTO.ofCheckPoint
            }
        let toWorkGroup (dto: WorkGroupDTO) : WorkGroup =
            {
                Title = dto.Title
                CurrentState = dto.CurrentState |> Array.map WorkItemDTO.toWorkItem
                Checkpoints = dto.Checkpoints |> Array.map CheckpointDTO.toCheckpoint
            }


    let toJson (data: WorkGroup) : string =
        data
        |> WorkGroupDTO.ofWorkGroup
        |> JSON.stringify 

    let fromJson (jsonString: string) : WorkGroup =
        jsonString
        |> JSON.parse
        |> WorkGroupDTO.toWorkGroup
namespace HillChart.Client

open HillChart.Client.Domain
open System

module DemoData =
    let demoWorkGroup : WorkGroup = {
        Title = "HillChart Demo"
        CurrentState = 
            [|
                {
                    id = WorkItemId.create()
                    color = Color.ofWebColor "red"
                    description = "Late task"
                    progress = WorkItemProgress.ofPercentage 12.069770990416055
                }
                {
                    id = WorkItemId.create()
                    color = Color.ofWebColor "yellow"
                    description = "Gettin there"
                    progress = WorkItemProgress.ofPercentage 55.11627906976744
                }
                {
                    id = WorkItemId.create()
                    color = Color.ofWebColor "green"
                    description = "Aww yeah!"
                    progress = WorkItemProgress.ofPercentage 93.48837209302326
                }
            |]
        Checkpoints = [| 
            { DateRepresented = DateTime.UtcNow.AddDays(-1) 
              WorkItems = [|
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "red"
                      description = "Late task"
                      progress = WorkItemProgress.ofPercentage 0
                  }
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "yellow"
                      description = "Gettin there"
                      progress = WorkItemProgress.ofPercentage 40.11627906976744
                  }
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "green"
                      description = "Aww yeah!"
                      progress = WorkItemProgress.ofPercentage 90.48837209302326
                  }|]
            }
            { DateRepresented = DateTime.UtcNow.AddDays(-2) 
              WorkItems = [|
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "red"
                      description = "Late task"
                      progress = WorkItemProgress.ofPercentage 0
                  }
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "yellow"
                      description = "Gettin there"
                      progress = WorkItemProgress.ofPercentage 20
                  }
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "green"
                      description = "Aww yeah!"
                      progress = WorkItemProgress.ofPercentage 40
                  }|]
            }
            { DateRepresented = DateTime.UtcNow.AddDays(-3) 
              WorkItems = [|
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "red"
                      description = "Late task"
                      progress = WorkItemProgress.ofPercentage 0
                  }
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "yellow"
                      description = "Gettin there"
                      progress = WorkItemProgress.ofPercentage 10
                  }
                  {
                      id = WorkItemId.create()
                      color = Color.ofWebColor "green"
                      description = "Aww yeah!"
                      progress = WorkItemProgress.ofPercentage 20
                  }|]
            }
        |]
    }

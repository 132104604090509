namespace HillChart.Client.Imports

module HillChartJs =

    open System
    open Fable.Core
    open Fable.Core.JsInterop
    open Browser.Types




    // type PointColor =
    //     | Name of string
    //     | Hex of string

    type ProgressPoint = {
        id: string
        color: string
        description: string
        size: int option
        x: double
        y: double option
        // link: string option
    }

    type Rems = double

    type FooterConfig = {
        show: bool
        fontSize: Rems
    }

    type ChartConfig = {
        target: string
        width: int
        height: int
        preview: bool 
        // darkMode: bool option
        backgroundColor: string option
        // footerText: FooterConfig option
        // not going to worry about margin for now
    }

    type ChartMargins = {
        top: int
        left: int
        right: int
        bottom: int
    }


    Fable.Core.JsInterop.importAll "hill-chart/dist/cjs/styles.css"
    [<ImportDefault "hill-chart">]
    type HillChart (data: ProgressPoint array, config: ChartConfig) =
        member this.on (eventname:string) () = jsNative

        member this.render () = jsNative

        member this.svg : HTMLElement = jsNative
        member this.target : string = jsNative
        member this.data : ProgressPoint array = jsNative
        member this.height : int  = jsNative
        member this.width : int  = jsNative

        member _.margin : ChartMargins = jsNative

        member _.chartWidth: int = jsNative
        member _.chartHeight: int = jsNative

        [<Emit "$0.on('pointClick',$1)">]
        member this.onPointClick (handler: ProgressPoint -> unit) = jsNative

        /// Thrown when a point is moved
        [<Emit "$0.on('moved',$1)">]
        member this.onMoved (handler: ProgressPoint -> unit) = jsNative


    open Fable.React

    module React =

        module Props =
            type ChartOptions = {
                elementId: string option
                afterRender: HillChart -> unit
                onMoved: ProgressPoint -> unit
                onPointClick: ProgressPoint -> unit
                preview: bool
            }
            let default' : ChartOptions = {
                elementId = None
                afterRender = ignore
                onMoved = ignore
                onPointClick = ignore
                preview = false
            }
            let onMoved onMoved (options:ChartOptions) =
                {options with onMoved = onMoved }

            let onPointClick onPointClick (options:ChartOptions) =
                {options with onPointClick = onPointClick }

            let preview (isPreview: bool) (options:ChartOptions) = 
                { options with preview = isPreview}



        let renderHillChart (points: ProgressPoint array) (options: Props.ChartOptions)=

            let svgId = options.elementId |> Option.defaultValue $"hillchart-{Guid.NewGuid()}"

            let demoConfig = {
                target = $"#{svgId}"
                height = 270
                width = 700
                preview = options.preview
                backgroundColor = Some "#ffffff"
            }

            let removeChildren (element: Element) =
                if(element.hasChildNodes()) then
                    element?replaceChildren([||])

            let reactElement = 
                Fable.React.Standard.svg [
                    Props.Id svgId 
                    Props.Ref (fun element ->
                        let renderFlagClass = "rendered"
                        if not (isNull element)
                        then 
                            if element.classList.contains renderFlagClass then removeChildren element
                                
                            let chartRef = HillChart (points, demoConfig)
                            chartRef.render()
                            element.classList.add(renderFlagClass)

                            chartRef.onPointClick(options.onPointClick)
                            chartRef.onMoved(options.onMoved)

                            options.afterRender chartRef
                    )
                ] []

            reactElement

    type DisposableChart (points: ProgressPoint array, config: ChartConfig, containerElement: Element) =
        inherit HillChart(points, config)

        member _.ContainerElement : Element = containerElement
        member this.SvgElement : Element = Browser.Dom.document.querySelector(this.target)
        interface IDisposable with 
            member this.Dispose() = 
                this.ContainerElement.remove()

    let offscreenHillchart (points: ProgressPoint array) =
        let svgId = $"hillchart-{Guid.NewGuid()}"

        let demoConfig = {
            target = $"#{svgId}"
            height = 270
            width = 700
            preview = false
            backgroundColor = Some "#ffffff"
        }
        
        let hiddenSvgParent = Browser.Dom.document.createElement("div")
        hiddenSvgParent?``style``?display <- "none"

        let svgElement = Browser.Dom.document.createElementNS("http://www.w3.org/2000/svg", "svg")
        svgElement.id <- svgId

        hiddenSvgParent.appendChild(svgElement) |> ignore
        Browser.Dom.document.body.appendChild hiddenSvgParent |> ignore

        let chartRef = new DisposableChart (points, demoConfig, hiddenSvgParent)
        chartRef.render()        

        chartRef


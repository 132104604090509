namespace HillChart.Client.Imports.GifJs
open Fable.Core
open Fable.Core.JsInterop

// type Repeat = 
//     | [<Emit "-1">] None 
//     | [<Emit "0">]Forever
//     | [<Emit "$0">] Count of int


type SampleInterval = int

// type GifOptions = {
//     repeat: int
//     /// pixel sample interval
//     // quality: SampleInterval
//     workers: int option
//     workerScript: string option
// }
module Defaults = 
    let delay = 500

[<AllowNullLiteral>]
[<Global>]
type FrameOptions
    [<ParamObject; Emit("$0")>]
    (?delay: int) =
    member val delay: int = jsNative with get, set


[<AllowNullLiteral>]
[<Global>]
type GifOptions
    [<ParamObject; Emit("$0")>]
    (?workers: int, ?workerScript: string) =
    member val workers: int = jsNative with get, set
    member val workerScript: string = jsNative with get, set


[<ImportDefault("gif.js")>]
type Gif (?config: GifOptions) = 
    member _.addFrame (media:obj, ?options:FrameOptions) = jsNative

    [<Emit "$0.on('finished',$1)">]
    member _.onFinish (handler: Browser.Types.Blob -> unit) = jsNative


    member _.render () = jsNative



module GifExtensions =
    let render_promise (gif: Gif) : JS.Promise<Browser.Types.Blob> =
        Promise.create (fun resolve reject ->
            gif.onFinish resolve
            gif.render ()
        )
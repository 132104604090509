namespace HillChart.Client.HillChartJsExtensions

open HillChart.Client.Domain
open HillChart.Client.Imports.HillChartJs

module ProgressPoint =
    let ofWorkItem (workItemState: WorkItem) : ProgressPoint =
        {
            id = Some workItemState.id |> string 
            color = Color.toWebColor workItemState.color
            description = workItemState.description
            x = workItemState.progress
            size = None
            y = None
        }

    let toWorkItem (point: ProgressPoint) : WorkItem =
        {
            id = WorkItemId.ofString point.id
            color = Color.ofWebColor point.color
            description = point.description
            progress = point.x
        }

    let workItemsToChartData (workItems:WorkItem array) =
        let points = workItems |> Array.map ofWorkItem
        points
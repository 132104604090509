module Index

open Elmish
open HillChart.Client.Imports
open HillChart.Client.Domain
open System
open HillChart.Client.Imports.Browser
open HillChart.Client
open HillChart.Client.HillChartJsExtensions

type SelectedChartVersion = 
    | Checkpoint of WorkGroupCheckpoint
    | Current 

type Model = { 
    CurrentWorkGroup: WorkGroup
    SelectedWorkItem: WorkItem option
    SelectedChartVersion: SelectedChartVersion
    Input: string 
}

type Msg =
    | SetInput of string
    | SetWorkGroupTitle of string
    | AddWorkItem of WorkItem
    | WorkItemMoved of WorkItem
    | WorkItemSelected of WorkItem
    | ClearWorkItemSelection 
    | RestoreDemoData 
    | RemoveSelectedPoint
    | ChartVersionSelected of SelectedChartVersion
    | SaveCheckpoint
    | ExportProgressionGif
    | ExportImage
    | SaveTo 
    | LoadFromFile
    | SetWorkGroupData of WorkGroup
    | NewWorkGroup 


module State =
    open Browser.WebStorage
    open HillChart.Client.Serialization
    open Fable.Core.JsInterop

    let private appstateKey = "work-items"

    
    let tryGetWork () : Option<WorkGroup> =
        let storedData = localStorage.getItem(appstateKey)
        if isNull storedData then 
            None
        else storedData |> Serialization.fromJson |> ((!!) >> Some)

    let saveWork (workGroup: WorkGroup) : unit =
        localStorage.setItem(appstateKey, workGroup |> Serialization.toJson)


let init () : Model * Cmd<Msg> =
    let model = { 
        Input = "" 
        CurrentWorkGroup = State.tryGetWork () |> Option.defaultValue DemoData.demoWorkGroup
        SelectedWorkItem = None
        SelectedChartVersion = Current
    }
    Browser.Dom.console.log("Loaded State", model.CurrentWorkGroup)

    model, Cmd.none

module Export =
    open HillChart.Client.Imports.GifJs
    open HillChart.Client.Imports.SaveSvgAsPng
    let svgToLoadedPng svgElement =
        promise{
            let! pngDataUrl = SaveSvgToPng.svgAsPngUri svgElement
            let img = Browser.Dom.Image.Create()
            let loadPromise = Image.awaitLoad img
            img.src <- pngDataUrl
            do! loadPromise
            return img
        }
        
    let addTitleToHillChart (title:string) (chartRef: HillChartJs.DisposableChart) : Browser.Types.Element = 
        let titleElement = Browser.Dom.document.createElementNS("http://www.w3.org/2000/svg", "text")
        let titleMargin_top = 5
        let titleSize = 20
        titleElement.setAttribute("x","50%")
        titleElement.setAttribute("y", string titleMargin_top)
        // Consider moving much of this to css?
        titleElement.setAttribute("dominant-baseline", "text-before-edge")
        titleElement.setAttribute("text-anchor", "middle")
        titleElement.setAttribute("font-size", $"{titleSize}px")
        titleElement.textContent <- title

        let additionalHeight = titleSize + titleMargin_top
        let svgElement = chartRef.SvgElement
        svgElement.setAttribute("height", chartRef.height + additionalHeight |> string)

        let offsetGroup = svgElement.getElementsByTagName("g")[0];
        offsetGroup.setAttribute("transform", $"translate({chartRef.margin.left}, {chartRef.margin.top + additionalHeight})")
        svgElement.setAttribute("height", chartRef.height + titleSize + titleMargin_top |> string)

        svgElement.appendChild(titleElement) |> ignore

        svgElement

    let formatTitle (workGroupTitle:string) (date: DateTime) =
        $"{workGroupTitle} - {date.ToShortDateString()}"
 

    let renderChartFrames (chartStates: HillChartJs.ProgressPoint array array) : Fable.Core.JS.Promise<Browser.Types.Blob> =
        promise {
            let gif = Gif()

            let isFirstFrame i = i = 0
            let isLastFrame i = i = (chartStates.Length - 1)

            for i in 0..(chartStates.Length-1) do 
                let chartState = chartStates[i]
                Browser.Dom.console.log("frame state: ", chartState)
                use chartRef = HillChartJs.offscreenHillchart chartState
                let! loadedPng = svgToLoadedPng chartRef.SvgElement
                
                let frameDelay = 
                    if isFirstFrame i then GifJs.Defaults.delay * 3
                    else if isLastFrame i then GifJs.Defaults.delay * 2
                    else GifJs.Defaults.delay

                gif.addFrame (loadedPng,FrameOptions(frameDelay))

            return! GifExtensions.render_promise gif
        }

    open Fable.Core.JsInterop
    open Fable.Core
    let dataUriToBlob (uri:string) = 
        // SOURCE: ported from https://github.com/exupero/saveSvgAsPng/blob/96484668c131d8a4babd82faa8a9d5bfdcaed64a/src/saveSvgAsPng.js#L81
        let [|typeSpecifier;byteString|] = uri.Split(',')
        let bytes = Browser.Dom.window.atob(byteString)
        let mimeString = (typeSpecifier.Split(':')[1]).Split(';')[0]
        let buffer = JS.Constructors.ArrayBuffer.Create(bytes.Length);
        let intArray = JS.Constructors.Uint8Array.Create(buffer);
        for i in 0..(byteString.Length - 1) do
            intArray[i] <- bytes?charCodeAt(i);
        
        Browser.Blob.Blob.Create([|buffer|], jsOptions<Browser.Types.BlobPropertyBag>(fun o -> o.``type`` <- mimeString ));
  

    let exportGif (workGroup: WorkGroup) =
        promise {

            let checkpoints = 
                Array.append workGroup.Checkpoints [| WorkGroup.checkpointFromCurrent workGroup |] 
                |> Array.sortBy _.DateRepresented 

            let gif = Gif()

            let isFirstFrame i = i = 0
            let isLastFrame i = i = (checkpoints.Length - 1)

            for i in 0..(checkpoints.Length-1) do 
                let checkpoint = checkpoints[i]

                use chartRef = HillChartJs.offscreenHillchart (checkpoint.WorkItems |> ProgressPoint.workItemsToChartData)
                let title = formatTitle workGroup.Title checkpoint.DateRepresented
                let svgWithTitle = addTitleToHillChart title chartRef

                let! loadedPng = svgToLoadedPng svgWithTitle
                
                let frameDelay = 
                    if isFirstFrame i then GifJs.Defaults.delay * 3
                    else if isLastFrame i then GifJs.Defaults.delay * 2
                    else GifJs.Defaults.delay

                gif.addFrame (loadedPng,FrameOptions(frameDelay))

            return! GifExtensions.render_promise gif
        }

    let exportPng (workGroupTitle: string) (checkpoint: WorkGroupCheckpoint) =
        promise {
            let chartData = checkpoint.WorkItems |> ProgressPoint.workItemsToChartData
            use chartRef = HillChartJs.offscreenHillchart chartData
            
            let chartTitle = formatTitle workGroupTitle checkpoint.DateRepresented
            let! pngDataUrl = SaveSvgToPng.svgAsPngUri (addTitleToHillChart chartTitle chartRef)
            
            return (pngDataUrl |> dataUriToBlob)
        }

    let openAsTab (blob: Browser.Types.Blob) : unit =
        let objUrl = Browser.Url.URL.createObjectURL blob
        Browser.Dom.window.``open``(url = objUrl) |> ignore
    


module ModelFiles = 
    let allowedFileTypes = AcceptFileType.makeAcceptObject [| {mimeType = "application/json"; extensions =[|".json"|] }|]
    let fileTypeSpec = [|FilePickerTypeRestriction(accept = allowedFileTypes)|]


let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
     
    match msg with
    | SetInput value -> { model with Input = value }, Cmd.none
    | SetWorkGroupTitle value -> {model with CurrentWorkGroup.Title = value}, Cmd.none
    | AddWorkItem workItem ->
        let updatedModel = 
            {model with 
                CurrentWorkGroup.CurrentState = Array.append model.CurrentWorkGroup.CurrentState [| workItem|]
                SelectedWorkItem = Some workItem
                Input = ""}
        
        updatedModel, Cmd.none
    | WorkItemMoved workItem ->
        { model with
            CurrentWorkGroup = model.CurrentWorkGroup |> WorkGroup.replacePointById workItem
        }, Cmd.ofMsg (WorkItemSelected workItem)
    | WorkItemSelected point ->
        {model with SelectedWorkItem = Some point}, Cmd.none
    | ClearWorkItemSelection ->
        { model with SelectedWorkItem = None}, Cmd.none
    | RemoveSelectedPoint ->
        match model.SelectedWorkItem with
        | None -> model,Cmd.none
        | Some workItem ->
            let updatedModel =
                { model with 
                    CurrentWorkGroup.CurrentState = model.CurrentWorkGroup.CurrentState |> WorkItem.removeWorkItem workItem
                    SelectedWorkItem = None}
            updatedModel, Cmd.none

    | ChartVersionSelected version ->
        { model with SelectedChartVersion = version }, Cmd.none
    | SaveCheckpoint ->
        let checkpointFromCurrent : WorkGroupCheckpoint = WorkGroup.checkpointFromCurrent model.CurrentWorkGroup

        { model with
            CurrentWorkGroup.Checkpoints = Array.append model.CurrentWorkGroup.Checkpoints [| checkpointFromCurrent |] 
        }, Cmd.none
    | RestoreDemoData ->
        {model with 
            CurrentWorkGroup = DemoData.demoWorkGroup
            SelectedWorkItem = None}
        , Cmd.none
    | ExportProgressionGif ->
        Export.exportGif model.CurrentWorkGroup 
        |> Promise.iter Export.openAsTab
        model, Cmd.none
    | ExportImage ->
        let checkpoint = 
            match model.SelectedChartVersion with
            | Checkpoint checkpoint -> checkpoint
            | Current -> WorkGroup.checkpointFromCurrent model.CurrentWorkGroup

        Export.exportPng model.CurrentWorkGroup.Title checkpoint 
        |> Promise.iter Export.openAsTab

        model, Cmd.none
    | SaveTo ->
        promise {
            let! handle = FileHandle.showSaveFilePicker(FilePickerOptions(suggestedName = "suggested.json", types = ModelFiles.fileTypeSpec))

            let modelJson = Serialization.Serialization.toJson model.CurrentWorkGroup

            let! writable = handle.createWritable()
            do! writable.write(modelJson)
            do! writable.close()
        } |> Promise.start
        model, Cmd.none
    | LoadFromFile ->
        
        let loadFilePromise () = 
            promise {
                let! [|saveFile|] = FileHandle.showOpenFilePicker(OpenFileOptions(multiple = false, types = ModelFiles.fileTypeSpec))
                
                let saveFileHandle : FileSystemFileHandle = 
                    match saveFile.kind with 
                    | Directory -> failwith "can't load a directory"
                    | File -> (Fable.Core.JsInterop.(!!)saveFile)

                let! saveFile = saveFileHandle.getFile()
                let! workGroupJson = saveFile.text()
                let importedWorkGroup = Serialization.Serialization.fromJson workGroupJson

                return importedWorkGroup
            } 
        model, Cmd.OfPromise.perform loadFilePromise () SetWorkGroupData

    | SetWorkGroupData workGroup ->
        {model with CurrentWorkGroup = workGroup}, Cmd.none
    | NewWorkGroup ->
        let emptyWorkGroup : WorkGroup = {
            Title = ""
            CurrentState = [||]
            Checkpoints = [||]
        }
        { model with 
            CurrentWorkGroup = emptyWorkGroup
            SelectedWorkItem = None
            SelectedChartVersion = Current
            Input = ""}, Cmd.none
    
    

let updateWithSave (msg: Msg) (model: Model): (Model * Cmd<Msg>) =
    // NOTE: I frame this as exclusion so that we err toward saving
    //      saving too often seems a less bad defect than not saving
    // TODO: I might want to add a debounce for work title changes. That could be a lot of file system calls
    let updatedModel,cmd = update msg model 
    match msg with 
    | SetInput _| ExportImage | ExportProgressionGif | WorkItemSelected _| SaveTo | LoadFromFile | ChartVersionSelected _ -> 
        ()
    | _ ->
        State.saveWork updatedModel.CurrentWorkGroup

    updatedModel,cmd


open Feliz
open Feliz.Bulma

let navBrand =
    Bulma.navbarBrand.div [
        // Bulma.navbarItem.a [
        //     prop.href "https://safe-stack.github.io/"
        //     navbarItem.isActive
        //     prop.children [
        //         Html.img [
        //             prop.src "/favicon.png"
        //             prop.alt "Logo"
        //         ]
        //     ]
        // ]
    ]

open HillChartJs.React

module BulmaExt =
    let controlButton (props: IReactProperty list) =
        Bulma.control.div [
            Bulma.button.a props
        ]

    let toLevelItem (el: ReactElement) =
        Bulma.levelItem [el] 
    let toBlock (el: ReactElement) =
        Bulma.block [el] 
    let toSection (el: ReactElement) =
        Bulma.section [
            // section.isMedium
            prop.children [el]
        ] 

    let toBox (el: ReactElement) =
        Bulma.box [el]

let containerBox (model: Model) (dispatch: Msg -> unit) =
    Bulma.box [
        Bulma.input.text [
            prop.classes ["chart-title"]
            prop.value model.CurrentWorkGroup.Title
            prop.onChange (fun x -> SetWorkGroupTitle x |> dispatch)
            prop.placeholder "What work does this chart describe / chart title"
        ]

        match model.SelectedChartVersion with
        | Checkpoint checkpoint ->
            renderHillChart 
                (checkpoint.WorkItems |> ProgressPoint.workItemsToChartData) 
                (HillChartJs.React.Props.default'
                    |> Props.preview true)
        | Current ->
            HillChartJs.React.renderHillChart 
                (model.CurrentWorkGroup.CurrentState |> ProgressPoint.workItemsToChartData)
                (Props.default' |> Props.onMoved (ProgressPoint.toWorkItem >> WorkItemMoved >> dispatch))
            
            
            Bulma.levelLeft [
                Html.p [ prop.text "Selected point: "] |> BulmaExt.toLevelItem
                Bulma.control.p [
                    prop.text (model.SelectedWorkItem |> Option.map _.description |> Option.defaultValue "-- No selection --")
                ] |> BulmaExt.toLevelItem
                if Option.isSome model.SelectedWorkItem then
                    Bulma.button.a [
                        button.isSmall
                        color.isLight
                        prop.disabled (Option.isNone model.SelectedWorkItem)
                        prop.onClick (fun _ -> dispatch ClearWorkItemSelection)
                        prop.text "clear"
                    ] |> BulmaExt.toLevelItem
                    Bulma.button.a [
                        color.isDanger
                        button.isSmall
                        prop.disabled (Option.isNone model.SelectedWorkItem)
                        prop.onClick (fun _ -> 
                            dispatch RemoveSelectedPoint 
                        )
                        prop.text "Remove"
                    ] |> BulmaExt.toLevelItem
            ] |> BulmaExt.toBlock


            Bulma.field.div [
                field.isGrouped
                prop.children [
                    Bulma.control.p [
                        control.isExpanded
                        prop.children [
                            Bulma.input.text [
                                prop.value model.Input
                                prop.placeholder "Task name"
                                prop.onChange (fun x -> SetInput x |> dispatch)
                            ]
                        ]
                    ]
                    Bulma.control.p [
                        Bulma.button.a [
                            color.isSuccess
                            prop.disabled (String.IsNullOrEmpty model.Input)
                            prop.onClick (fun _ -> 
                                let point : WorkItem = {
                                    id = WorkItemId.create ()
                                    color = Color.ofWebColor "blue"
                                    description = model.Input
                                    progress = WorkItemProgress.ofPercentage 0
                                }
                                dispatch (AddWorkItem point)
                            )
                            prop.text "Add point"
                        ]
                        
                    ]

                ]
            ]
                
        Bulma.buttons [
            Bulma.button.a [
                color.isPrimary
                prop.onClick (fun _ -> dispatch SaveTo)
                prop.text "Save File"
            ]
            Bulma.button.a [
                color.isPrimary
                prop.onClick (fun _ -> dispatch LoadFromFile)
                prop.text "Load file"
            ]
            Bulma.button.a [
                color.isWarning
                prop.onClick (fun _ -> dispatch NewWorkGroup)
                prop.text "Blank Chart"
            ]
        ]
            
        Bulma.buttons [
            Bulma.button.a [
                color.isPrimary
                prop.onClick (fun _ -> dispatch ExportProgressionGif)
                prop.text "Export Gif"
            ]

            Bulma.button.a [
                color.isPrimary
                prop.onClick (fun _ -> dispatch ExportImage)
                prop.text "Export single"
            ]
        ]
        Bulma.levelLeft [
            Html.h3 [
                prop.text "Checkpoints"
                prop.style [
                    style.fontSize (Feliz.length.rem 1.5)
                ]
            ] |> BulmaExt.toLevelItem

            Bulma.button.a [
                color.isSuccess
                button.isSmall
                prop.onClick (fun _ -> dispatch SaveCheckpoint)
                prop.text "Save Checkpoint"
            ] |> BulmaExt.toLevelItem
        ]
        Bulma.content [
            Html.ul [
                let isSelectedVersion (version:SelectedChartVersion) =
                    version = model.SelectedChartVersion
                let selectedClass = "is-active"

                prop.classes ["version-list"]
                prop.children [
                    Html.li [
                        prop.text "Current"
                        prop.onClick (fun _ -> dispatch (ChartVersionSelected Current))
                        prop.classes [if isSelectedVersion Current then selectedClass]
                    ]
                    for checkpoint in model.CurrentWorkGroup.Checkpoints |> Array.sortByDescending _.DateRepresented do
                        Html.li [ 
                            prop.text (checkpoint.DateRepresented.ToString())
                            prop.classes [if isSelectedVersion (Checkpoint checkpoint) then selectedClass]
                            prop.onClick (fun _ -> dispatch (checkpoint |> Checkpoint |> ChartVersionSelected)) ]
                ]
            ]
        ]

    ]

let view (model: Model) (dispatch: Msg -> unit) =
    Bulma.hero [
        hero.isFullHeight
        color.isInfo
        prop.style [
            style.backgroundSize "cover"
            style.backgroundPosition "no-repeat center center fixed"
        ]
        prop.children [
            Bulma.heroHead [
                Bulma.navbar [
                    Bulma.container [ navBrand ]
                ]
            ]
            Bulma.heroBody [
                Bulma.container [
                    Bulma.column [
                        column.is8
                        column.isOffset2
                        prop.children [
                            Bulma.title [
                                text.hasTextCentered
                                prop.text "Hill Chart Editor"
                            ]
                            containerBox model dispatch
                        ]
                    ]
                ]
            ]
        ]
    ]